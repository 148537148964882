export const products = [
  {
    image:
      "https://cdn.sanity.io/images/hon77rsr/production/058225fc820fe15a1c63697367a905959a5f32a6-555x555.webp",
    category: "product",
    id: "90556b4c-b001-4089-ba06",
    updated_date: "2022-09-08T19:14:52Z",
    slug: {
      current: "headphones_new",
      _type: "slug",
    },
    regular_price: 120,
    offer_price: 55,
    created_at: "2022-09-08T19:14:52Z",
    name: "EKSA E4 AirComfy Noise Cancelling Headphone",
    details: "A second pair of headphones",
    rating: 3,
    total_rating: 12,
  },
  {
    image:
      "https://cdn.sanity.io/images/hon77rsr/production/9fbb62343426e1f157144f26d9b59be1629ef7c1-600x600.webp",
    category: "product",
    id: "b001-4089-ba06-ac9e66229c15",
    updated_date: "2022-09-08T19:14:52Z",
    slug: {
      current: "speaker",
      _type: "slug",
    },
    regular_price: 100,
    offer_price: 55,
    created_at: "2022-09-08T19:14:52Z",
    name: "Speaker",
    details: "Immerse in the amazing sound",
    rating: 4,
    total_rating: 23,
  },
  {
    image:
      "https://cdn.sanity.io/images/hon77rsr/production/07fd4b12012f56f93ee9c5090a09754b4d8ee9dd-600x600.webp",
    category: "product",
    id: "90556b4c-b001-ba06-ac9e66229c15",
    updated_date: "2022-09-08T19:14:52Z",
    slug: {
      current: "speaker",
      _type: "slug",
    },
    regular_price: 70,
    offer_price: 55,
    created_at: "2022-09-08T19:14:52Z",
    name: "Speaker",
    details: "Immerse in the amazing sound",
    rating: 4,
    total_rating: 45,
  },
  {
    image:
      "https://cdn.sanity.io/images/hon77rsr/production/058225fc820fe15a1c63697367a905959a5f32a6-555x555.webp",
    category: "product",
    id: "90556b4c-b001-4089-ac9e66229c15",
    updated_date: "2022-09-08T19:14:52Z",
    slug: {
      current: "speaker",
      _type: "slug",
    },
    regular_price: 55,
    created_at: "2022-09-08T19:14:52Z",
    name: "Speaker",
    details: "Immerse in the amazing sound",
    rating: 5,
    total_rating: 6,
  },
];

export const productDetails = {
  rating: 12,
  image: [
    "https://cdn.sanity.io/images/hon77rsr/production/a64b345016e96adfb8849af5521c8e0ecfe8f027-555x555.webp",
    "https://cdn.sanity.io/images/hon77rsr/production/07fd4b12012f56f93ee9c5090a09754b4d8ee9dd-600x600.webp",
    "https://sony.scene7.com/is/image/sonyglobalsolutions/wh-ch520_Primary_image?$categorypdpnav$&fmt=png-alpha",
    "https://d1gb7gicmr8iau.cloudfront.net/fit-in/1920x800/Media/Images/Product/Visual/23106_pictures_product_visual_1.png",
  ],
  name: "EKSA E4 AirComfy Noise Cancelling Headphone",
  regular_price: 120,
  offer_price: 99,
  _rev: "u6z14vWwH5lzTVbDpInOfZ",
  created_at: "2022-09-08T12:42:03Z",
  category: "product",
  id: "f2bce0b1-a6b8-47da-9d48-fd54be522f24",
  updated_at: "2022-09-08T12:42:03Z",
  slug: {
    current: "headphones",
    _type: "slug",
  },
  total_rating: 6,
  updated_date: "2022-09-08T19:14:52Z",

  details: [
    "Kodomo Baby Soap is a great way to get your baby’s skin squeaky clean without the harmful chemicals.",
    "This baby soap has mild moisturizing ingredients that help keep your little angel’s skin soft and supple.",
    "The fragrance in Kodomo Baby Soap is mild and not overpowering, keeping babies smelling pleasant.",
    "Bath time is going to be more fun than ever before as the soap lathers easily, much to the delight of your baby.",
    "The hypoallergenic formula is suitable for the delicate skin of young children and adults with sensitive skin.",
    "This superior quality of baby soap is simply the best.",
  ],
};

export const footerBanner = {
  _updatedAt: "2022-09-08T13:44:21Z",
  _rev: "772MCeNIooHnNdtyU0ZzK6",
  largeText2: "SMILE",
  saleTime: "15 Nov to 7 Dec",
  _id: "df686bc1-1465-41fc-b4fd-5b5079fd4ba5",
  desc: "Best headphones on the market",
  midText: "Summer Sale",
  _type: "banner",
  smallText: "Beats Solo Air",
  largeText1: "FINE",
  _createdAt: "2022-09-08T13:44:21Z",
  buttonText: "Shop Now",
  product: "headphones",
  discount: "20% OFF",
  image: {
    _type: "image",
    asset: {
      _ref: "image-a64b345016e96adfb8849af5521c8e0ecfe8f027-555x555-webp",
      _type: "reference",
    },
  },
};
