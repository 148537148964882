import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICartProducts } from "../../interfaces/common.interfaces";

// Define the initial state and its type

interface CartState {
  items: ICartProducts[];
}

const initialState: CartState = {
  items: JSON.parse(localStorage.getItem("cart") || "[]") || [],
};

// Create a slice of the state
const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addProduct: (state, action: PayloadAction<ICartProducts>) => {
      const existingProduct = state.items.find(
        (item) => item.id === action.payload.id
      );
      console.log({ pay: action.payload, existingProduct });
      if (existingProduct) {
        existingProduct.quantity += action.payload.quantity;
      } else {
        state.items.push(action.payload);
      }
      localStorage.setItem("cart", JSON.stringify(state.items));
    },

    incrementQuantity: (state, action: PayloadAction<number | string>) => {
      const item = state.items.find((item) => item.id === action.payload);
      if (item) {
        item.quantity += 1;
        localStorage.setItem("cart", JSON.stringify(state.items));
      }
    },
    decrementQuantity: (state, action: PayloadAction<number | string>) => {
      const item = state.items.find((item) => item.id === action.payload);
      if (item) {
        item.quantity -= 1;
        localStorage.setItem("cart", JSON.stringify(state.items));
      }
    },

    emptyCart: (state) => {
      state.items = []; // Set items to an empty array
      localStorage.removeItem("cart"); // Clear cart from localStorage
    },

    removeItem: (state, action: PayloadAction<number | string>) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
      localStorage.setItem("cart", JSON.stringify(state.items));
    },

    loadCart: (state) => {
      state.items = JSON.parse(localStorage.getItem("cart") || "[]") || [];
    },
  },
});

// Export actions and reducer
export const {
  addProduct,
  incrementQuantity,
  decrementQuantity,
  removeItem,
  loadCart,
  emptyCart,
} = cartSlice.actions;
export default cartSlice.reducer;
