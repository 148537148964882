import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

const Layout = () => {
  const location = useLocation();

  // Determine if the current route is the NotFound page
  const isNotFound = location.pathname === "*"; // Adjust this as needed

  return (
    <div>
      {!isNotFound && <NavBar />}
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
