type Props = {
  column: (
    | {
        title: string;
        dataIndex: string;
        key: string;
        render: (text: any, row: any, index: number) => JSX.Element;
      }
    | {
        title: string;
        dataIndex: string;
        key: string;
        render?: undefined;
      }
  )[];
  data?: any[];
};

const Table = ({ column, data }: Props) => {
  if (!data) {
    return <h2>No data found!</h2>;
  }

  return (
    <div className="relative overflow-x-auto shadow sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {column?.map((col, index) => {
              return (
                <th scope="col" className="px-6 py-3" key={index}>
                  {col.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.map((item: any, index) => {
            return (
              <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                {column.map((col) => {
                  return (
                    <td className="px-6 py-4">
                      {col?.render
                        ? col?.render(item[col.dataIndex], item, index)
                        : item[col.dataIndex]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
