import { useEffect, useState } from "react";
import { useGetCategoryQuery } from "../../../redux/api/authApi";
import Breadcrumbs from "../../../utils/components/Breadcrumbs";
import Table from "../../../utils/components/Table";

type Props = {};

const CategoryLIst = (props: Props) => {
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(search); // State for debounced search value

  // Fetch categories based on the debounced search term
  const { data, isSuccess, isLoading } = useGetCategoryQuery(debouncedSearch);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value); // Update search state immediately
  };

  // Debounce the search input, updating debouncedSearch after 0.4s delay
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search); // Update debounced search after delay
    }, 400);

    return () => {
      clearTimeout(handler); // Clear timeout if the user types before 0.4s
    };
  }, [search]);

  const column = [
    {
      title: "Sl",
      dataIndex: "id",
      key: "id",
      render: (text: any, row: any, index: number) => <span>{index + 1}</span>,
    },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Image", dataIndex: "img", key: "img" },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text: any, row: any) => (
        <span> {text === 0 ? "Inactive" : "Active"} </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "is_active",
      key: "is_active",
      render: (text: any, row: any) => (
        <>
          <button
            type="button"
            className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mr-2"
          >
            Edit
          </button>
          <button
            type="button"
            className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Delete
          </button>
        </>
      ),
    },
  ];

  return (
    <div className="w-screen min-h-screen bg-white">
      <Breadcrumbs title="Category List" />

      <div className="max-w-screen-lg px-2">
        <div className="sm:flex sm:items-center sm:justify-between flex-col sm:flex-row">
          <p className="flex-1 text-base font-bold text-gray-900">
            Category List
          </p>

          <div className="border-b-blue-600 focus-within:border-none focus-within:ring focus-within:ring-offset-2 my-10 flex h-10 items-center justify-start border-b-2 bg-gray-100 leading-4 ring-blue-600 sm:w-96">
            <input
              placeholder="Search"
              onChange={handleSearch}
              className="peer ml-2 flex-grow bg-transparent text-gray-500 outline-none"
            />
            <button
              type="button"
              className="peer-focus:mr-2 z-20 cursor-pointer text-blue-600 outline-none duration-150 hover:scale-125"
            >
              <svg className="h-6 w-6 stroke-2" viewBox="0 0 32 32" fill="none">
                <circle
                  cx="15"
                  cy="14"
                  r="8"
                  stroke="currentColor"
                  fill="transparent"
                ></circle>
                <line
                  x1="21.1514"
                  y1="19.7929"
                  x2="26.707"
                  y2="25.3484"
                  stroke="currentColor"
                  fill="transparent"
                ></line>
              </svg>
            </button>
          </div>
        </div>

        <Table data={data} column={column} />
      </div>
    </div>
  );
};

export default CategoryLIst;
