import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Checkout2 from "./components/Checkout2";
import Login from "./components/Login";
import ProductDetails from "./components/ProductDetails";
import ProductForm from "./components/ProductForm";
import Registration from "./components/Registration";
import ThankYouPage from "./components/ThankYouPage";
import CategoryLIst from "./features/admin/pages/CategoryLIst";
import Dashboard from "./pages/admin/AdminDashboard";
import AuthenticatedLayout from "./pages/admin/AuthenticatedLayout";
import Users from "./pages/admin/Users";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import NotFound from "./pages/NotFound";
import OrderLists from "./pages/OrderLists";
import { useRefreshTokenQuery } from "./redux/api/authApi";
import { setCredentials } from "./redux/slices/authSlice";
import { RootState } from "./redux/store/store";
function App() {
  const dispatch = useDispatch();
  const [fetchIntial, setFetchIntial] = useState(true);
  const { data, isSuccess, isLoading } = useRefreshTokenQuery("");

  const { user, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );

  useEffect(() => {
    if (data?.data) {
      dispatch(setCredentials(data?.data));
    }

    if (isLoading) {
      setFetchIntial(false);
    }
  }, [isSuccess, isLoading]);

  if (fetchIntial) {
    return <>Loading...</>;
  }

  return (
    <div className="bg-white">
      <Router>
        <Routes>
          {user ? (
            <Route path="/admin" element={<AuthenticatedLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="/admin/dashboard" element={<Dashboard />} />
              <Route path="/admin/users" element={<Users />} />
              <Route path="/admin/category" element={<CategoryLIst />} />
            </Route>
          ) : (
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/thank-you" element={<ThankYouPage />} />
              <Route path="/product-form" element={<ProductForm />} />
              <Route path="/checkout" element={<Checkout2 />} />
              <Route path="/registration" element={<Registration />} />
              <Route path="/product/details" element={<ProductDetails />} />
              <Route path="/orders" element={<OrderLists />} />
              {/* ADMIN ROUTE */}
              {/* <Route
              path="/admin"
              element={
                <AdminRoute>
                  <AdminPanel />
                </AdminRoute>
              }
            /> */}
            </Route>
          )}

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
