import { Link } from "react-router-dom";
import "../styles/footer.css";

type Props = {};

const Footer = (props: Props) => {
  return (
    <footer className="flex flex-col space-y-6 justify-center bg-gray-200 py-8">
      <nav className="flex justify-center flex-wrap gap-6 text-gray-500 font-medium">
        <Link className="hover:text-gray-900" to={"/"}>
          Home
        </Link>

        <Link to={"/products"} className="hover:text-gray-900">
          Services
        </Link>

        <Link className="hover:text-gray-900" to={"/galary"}>
          Features
        </Link>
        <Link to={"/#contact"} className="hover:text-gray-900">
          Contact
        </Link>
      </nav>

      <div className="flex justify-center space-x-5">
        <a
          href="https://www.facebook.com/cosmelics?mibextid=ZbWKwL"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="https://img.icons8.com/fluent/30/000000/facebook-new.png" />
        </a>

        <a
          href="https://www.instagram.com/cos_melic?igsh=MTBtbmxoYWY3NGFxZQ=="
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="https://img.icons8.com/fluent/30/000000/instagram-new.png" />
        </a>
        <a
          href="https://m.me/cosmelics"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="https://img.icons8.com/fluent/30/000000/facebook-messenger--v2.png" />
        </a>
        <a
          href="https://wa.me/8801626074776"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="https://img.icons8.com/fluent/30/000000/whatsapp.png" />
        </a>
      </div>
      <p className="text-center text-gray-700 font-medium">
        &copy; 2024 Cosmelic All rights reservered.
      </p>
    </footer>
  );
};

export default Footer;
