import React, { useState } from "react";

const ProductForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    image: [""],
    category: "",
    regular_price: "",
    offer_price: "",
    rating: "",
    total_rating: "",
    details: [""],
  });

  // Handle form input change
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle image URL change
  const handleImageChange = (index: number, value: string) => {
    const newImages = [...formData.image];
    newImages[index] = value;
    setFormData({ ...formData, image: newImages });
  };

  // Add a new image field
  const addImageField = () => {
    setFormData({
      ...formData,
      image: [...formData.image, ""],
    });
  };

  // Handle form submit
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <div className="bg-white h-[100vh]">
      <div className="border border-gray-100 shadow-gray-500/20 mt-8 mb-8 max-w-md bg-white shadow-sm sm:rounded-lg sm:shadow-lg lg:mt-0 mx-auto">
        <div className="relative border-b border-gray-300 p-4 py-8 sm:px-8">
          <h3 className="mb-1 inline-block text-3xl font-medium">
            <span className="mr-4">Add Product</span>
          </h3>
        </div>
        <div className="p-4 sm:p-8">
          <input
            id="name"
            type="text"
            className="mt-4 w-full resize-y overflow-auto rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-blue-500 focus:outline-none hover:border-blue-500"
            placeholder="Enter your name"
          />
          <input
            id="email"
            type="email"
            className="peer mt-8 w-full resize-y overflow-auto rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-blue-500 focus:outline-none hover:border-blue-500"
            placeholder="Enter your email"
          />
          <div className="peer-invalid:block mt-1 hidden text-left text-xs text-rose-600">
            Email format Incorrect. Please complete the email
          </div>
          <label className="mt-5 mb-2 inline-block max-w-full">
            Tell us about your company
          </label>
          <textarea
            id="about"
            className="mb-8 w-full resize-y overflow-auto rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-blue-500 focus:outline-none hover:border-blue-500"
          ></textarea>
          <button className="w-full rounded-lg border border-blue-700 bg-blue-700 p-3 text-center font-medium text-white outline-none transition focus:ring hover:border-blue-700 hover:bg-blue-600 hover:text-white">
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductForm;
