import { Link, useNavigate } from "react-router-dom";
import logo from "../assest/logo.png";
import "../styles/navbar.css";
import Drawer from "./Drawer";
import { useDispatch } from "react-redux";
import { logout } from "../redux/slices/authSlice";

function NavBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Example of Logout Function
  const handleLogout = () => {
    dispatch(logout());
    // Redirect to login page
    navigate("/login");
  };

  return (
    <header className="text-slate-700 container relative mx-auto flex flex-col overflow-hidden px-4 py-4 lg:flex-row lg:items-center shadow">
      <Link
        to="/"
        className="flex items-center whitespace-nowrap text-2xl font-black"
      >
        <img className="w-48" src={logo} alt="" />
      </Link>
      <input type="checkbox" className="peer hidden" id="navbar-open" />
      <label
        className="absolute top-5 right-5 cursor-pointer lg:hidden"
        htmlFor="navbar-open"
      >
        <svg
          className="h-7 w-7"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M4 6h16M4 12h16M4 18h16"
          ></path>
        </svg>
      </label>
      <nav
        aria-label="Header Navigation"
        className="peer-checked:pt-8 peer-checked:max-h-60 flex max-h-0 w-full flex-col items-center overflow-hidden transition-all lg:ml-24 lg:max-h-full lg:flex-row"
      >
        <ul className="flex w-full flex-col items-center space-y-2 lg:flex-row lg:justify-center lg:space-y-0">
          <li className="lg:mr-12">
            <a
              className="rounded text-gray-600 transition focus:outline-none focus:ring-1 focus:ring-blue-700 focus:ring-offset-2 font-bold hover:text-gray-800 text-lg"
              href="#"
            >
              Products
            </a>
          </li>
          <li className="lg:mr-12">
            <a
              className="rounded text-gray-600 transition focus:outline-none focus:ring-1 focus:ring-blue-700 focus:ring-offset-2 font-bold hover:text-gray-800 text-lg"
              href="#"
            >
              Features
            </a>
          </li>
          <li className="lg:mr-12">
            <a
              className="rounded text-gray-600 transition focus:outline-none focus:ring-1 focus:ring-blue-700 focus:ring-offset-2 font-bold hover:text-gray-800 text-lg"
              href="#"
            >
              Blogs
            </a>
          </li>
          <li className="lg:mr-12">
            <a
              className="rounded text-gray-600 transition focus:outline-none focus:ring-1 focus:ring-blue-700 focus:ring-offset-2 font-bold hover:text-gray-800 text-lg"
              href="#"
            >
              Contact
            </a>
          </li>
        </ul>
        <hr className="mt-4 w-full lg:hidden" />
        <div className="my-4 flex items-center space-x-6 space-y-2 lg:my-0 lg:ml-auto lg:space-x-8 lg:space-y-0">
          <Drawer />
        </div>
      </nav>
    </header>
  );
}

export default NavBar;
