import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ICartProducts, IProducts } from "../interfaces/common.interfaces";
import { addProduct } from "../redux/slices/cartSlice";
import { AppDispatch } from "../redux/store/store";
import "../styles/product.css";
import AddToCartButton from "../utils/components/AddToCart";

interface Props {
  product: IProducts;
}

const Product = ({ product }: Props) => {
  const { image, name, offer_price, regular_price, rating, total_rating } =
    product;

  const dispatch: AppDispatch = useDispatch();

  const addToCart = (product: ICartProducts) => {
    console.log({ product });

    dispatch(addProduct(product));
  };

  return (
    <div className="group relative">
      <Link to={`/product/details`}>
        <div className="product-card">
          <img src={image} className="product-image" />
          <p className="product-name">{name}</p>

          <div className="product-info">
            <p className="product-price">
              {offer_price && regular_price ? (
                <>
                  <span className="regular-price">${regular_price}</span>
                  <span className="offer-price">${offer_price}</span>
                </>
              ) : (
                <span>${regular_price}</span>
              )}
            </p>

            <div className="reviews">
              <div>
                {Array.from({ length: 5 }, (v, i) =>
                  i < rating ? (
                    <AiFillStar key={i} />
                  ) : (
                    <AiOutlineStar key={i} />
                  )
                )}
              </div>
              <p>({total_rating})</p>
            </div>
          </div>
        </div>
      </Link>

      {/* Add to Cart button */}
      <span className="absolute hidden w-full group-hover:block transition-all duration-400 ease-in-out top-28">
        <AddToCartButton
          onClick={() => {
            addToCart({ ...product, quantity: 1 });
          }}
        />

        <Link to={`/product/details`}>
          <button className="w-full bg-blue-600 hover:bg-blue-500 text-white font-bold py-1.5 rounded-full transition duration-300 ease-in-out mt-2">
            Details
          </button>
        </Link>
      </span>
    </div>
  );
  return (
    <div>
      <Link to={`/product/details`}>
        <div className="product-card">
          <img src={image} className="product-image" />
          <p className="product-name">{name}</p>

          <div className="product-info">
            <p className="product-price">
              {offer_price && regular_price ? (
                <>
                  <span className="regular-price">${regular_price}</span>
                  <span className="offer-price">${offer_price}</span>
                </>
              ) : (
                <span>${regular_price}</span>
              )}
            </p>

            <div className="reviews">
              <div>
                {
                  // Create an array of length 5, and for each index, check if it should be a filled star or outlined star
                  Array.from({ length: 5 }, (v, i) =>
                    i < rating ? (
                      <AiFillStar key={i} />
                    ) : (
                      <AiOutlineStar key={i} />
                    )
                  )
                }
              </div>
              <p>({total_rating})</p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Product;
