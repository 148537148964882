import { FaShoppingCart } from "react-icons/fa";
import "../styles/addToCart.css";

import React from "react";

interface AddToCartButtonProps {
  onClick: () => void;
}

const AddToCartButton: React.FC<AddToCartButtonProps> = ({ onClick }) => {
  return (
    <button
      className="button w-full"
      style={{ "--clr": "#00ad54" } as React.CSSProperties}
      onClick={onClick}
    >
      <span className="button-decor"></span>
      <div className="button-content">
        <div className="button__icon">
          <FaShoppingCart className="text-white" />
        </div>
        <span className="button__text">Add to Cart</span>
      </div>
    </button>
  );
};

export default AddToCartButton;
