// src/store/api/authApi.ts

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ICategory } from "../../features/admin/interfaces/product.interface";
import { ILoginResponse } from "../../interfaces/user.interface";

interface LoginRequest {
  username: string;
  password: string;
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "http://localhost:4000",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation<ILoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: "/users/login",
        method: "POST",
        body: credentials,
      }),
    }),

    refreshToken: builder.query<ILoginResponse, string>({
      query: () => ({
        url: "/users/refresh-token",
        method: "GET",
      }),
    }),

    // CATEGORY
    getCategory: builder.query<ICategory[], string>({
      query: (search) => ({
        url: `/products/category?search=${search}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLazyRefreshTokenQuery,
  useRefreshTokenQuery,
  useGetCategoryQuery,
} = authApi;
