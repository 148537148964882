import Contact from "../components/Contact";
import HeroBanner from "../components/HeroBanner";
import Products from "../components/Products";

type Props = {};

const Home = (props: Props) => {
  return (
    <div className="container mx-auto">
      <main>
        <HeroBanner />
        <Products />
        <Contact />
      </main>
    </div>
  );
};

export default Home;
