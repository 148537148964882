import { BsCashCoin } from "react-icons/bs";
import { FaAward } from "react-icons/fa6";
import { GiReturnArrow } from "react-icons/gi";
import { TbTruckDelivery } from "react-icons/tb";

import { useState } from "react";
import {
  AiFillStar,
  AiOutlineMinus,
  AiOutlinePlus,
  AiOutlineStar,
} from "react-icons/ai";
import { useDispatch } from "react-redux";
import { ICartProducts, IProducts } from "../interfaces/common.interfaces";
import { addProduct } from "../redux/slices/cartSlice";
import { AppDispatch } from "../redux/store/store";
import "../styles/productDetails.css";
import { productDetails, products } from "../utils/demoData";
import Toast from "../utils/Toast";
import Product from "./Product";

export default function ProductDetails() {
  const {
    image,
    name,
    details,
    offer_price,
    regular_price,
    category,
    created_at,
    id,
    rating,
    slug,
    total_rating,
    updated_date,
  } = productDetails;

  const CartProduct: IProducts = {
    category,
    created_at,
    details: details[0],
    id,
    image: image[0],
    name,
    rating,
    regular_price,
    slug,
    total_rating,
    updated_date,
    offer_price,
  };

  const [index, setIndex] = useState(0);
  const [quantity, setQuantity] = useState(1);

  const dispatch: AppDispatch = useDispatch();

  const [showToast, setShowToast] = useState(false);

  const triggerToast = () => {
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000); // Hide toast after 3 seconds
  };

  const addToCart = (product: ICartProducts) => {
    console.log({ product });

    dispatch(addProduct(product));
    triggerToast();
  };
  return (
    <div className="product-details bg-white">
      {showToast && (
        <Toast message="This is a success message!" type="success" />
      )}

      <div className="container-width">
        <div className="product-detail-container">
          <div className="image-info">
            <div className="image-container">
              <img src={image[index]} className="product-detail-image" />
            </div>
            <div className="small-images-container">
              {image?.map((item: any, i: number) => (
                <img
                  key={i}
                  src={item}
                  className={
                    i === index ? "small-image selected-image" : "small-image"
                  }
                  onMouseEnter={() => {
                    setIndex(i);
                  }}
                />
              ))}
            </div>
          </div>

          <div className="product-detail-desc w-3/5">
            <h1>{name}</h1>
            <span className="flex justify-between">
              <p className="price">
                {offer_price && regular_price ? (
                  <>
                    <span className="regular-price">${regular_price}৳</span>
                    <span className="offer-price">${offer_price}৳</span>
                  </>
                ) : (
                  <span>${regular_price}</span>
                )}
              </p>
              <div className="reviews">
                <div>
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                  <AiOutlineStar />
                </div>
                <p>(20)</p>
              </div>
            </span>

            <p className="coint-info">
              GET COINS WORTH ৳8 REDEEMABLE EXCLUSIVELY ON APP
            </p>

            <div className="quantity">
              <h3>Quantity</h3>
              <p className="quantity-desc">
                <button
                  disabled={quantity === 1}
                  onClick={(e) => setQuantity(quantity - 1)}
                  className="minus"
                >
                  <AiOutlineMinus />
                </button>
                <span className="num" onClick={() => {}}>
                  {quantity}
                </span>
                <button
                  className="plus"
                  onClick={(e) => setQuantity(quantity + 1)}
                >
                  <AiOutlinePlus />
                </button>
              </p>
            </div>

            <div className="buttons">
              <button
                type="button"
                className="add-to-cart"
                onClick={() => addToCart({ ...CartProduct, quantity })}
              >
                Add to Cart
              </button>
              <button type="button" className="buy-now" onClick={() => {}}>
                Buy Now
              </button>
            </div>

            <div className="delivery-info">
              <p className="flex items-center gap-2.5 text-lg my-3">
                <TbTruckDelivery /> Delivery inside Dhaka within 2 days Outside
                Dhaka 3-5 Days.
              </p>
              <p className="flex items-center gap-2.5 text-lg my-3">
                <FaAward /> 100% Original Branded Products.
              </p>

              <p className="flex items-center gap-2.5 text-lg my-3">
                <BsCashCoin /> COD available.
              </p>
              <p className="flex items-center gap-2.5 text-lg my-3">
                <GiReturnArrow />
                Easy Product Return.
                <span className="underline">T&C</span>
              </p>
            </div>
          </div>
        </div>

        <div className="product-description">
          <h4>Description</h4>
          <ul>
            {details?.map((item, index) => {
              return <li key={index}>{item}</li>;
            })}
          </ul>
        </div>

        <div className="maylike-products-wrapper">
          <h2>You may also like</h2>
          <div className="marquee">
            <div className="maylike-products-container track">
              {products?.map((item: any) => (
                <Product key={item.id} product={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
