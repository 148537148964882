import "../styles/heroBanner.css";

const heroBanner = {
  title: "25% OFF",
  seconderyTitle: "SUMMER SALE",
  description: "For limited time only!",
  buttonLebel: "SHOP NOW",
  imgUrl:
    "https://images.unsplash.com/photo-1615397349754-cfa2066a298e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1887&q=80",
};

const HeroBanner = () => {
  return (
    <section className="px-3 py-5 bg-neutral-100 lg:py-10 mt-8">
      <div className="grid lg:grid-cols-2 items-center justify-items-center gap-5">
        <div className="order-2 lg:order-1 flex flex-col justify-center items-center">
          <p className="text-4xl font-bold md:text-7xl text-main">
            {heroBanner.title}
          </p>
          <p className="text-4xl font-bold md:text-7xl">
            {heroBanner.seconderyTitle}
          </p>
          <p className="mt-2 text-sm md:text-lg">{heroBanner.description} </p>
          <button className="text-lg md:text-2xl bg-main text-white pt-1 pb-2 px-5 mt-10 hover:bg-purple-800 rounded-full ">
            {heroBanner.buttonLebel}
          </button>
        </div>
        <div className="order-1 lg:order-2">
          <img
            className="h-80 w-80 object-cover lg:w-[500px] lg:h-[500px]"
            src={heroBanner.imgUrl}
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default HeroBanner;
