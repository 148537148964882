import { Link } from "react-router-dom";
import "../styles/login.css";
type Props = {};

const Registration = (props: Props) => {
  return (
    <div className="login">
      <div className="form-container">
        <p className="title">Registration here</p>
        <form className="form">
          <input type="text" className="input" placeholder="Name" />
          <input type="email" className="input" placeholder="Email" />
          <input type="text" className="input" placeholder="Mobile" />
          <input type="password" className="input" placeholder="Password" />
          <button className="form-btn">Sign in</button>
        </form>
        <p className="sign-up-label">
          Don't have an account?{" "}
          <Link to={"/login"}>
            <span className="sign-up-link">Sign in</span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Registration;
